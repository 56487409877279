<template>
  <v-row
    class="awards-list-grid pb-4 pb-md-0"
    v-if="productList && productList.length"
  >
    <v-col
      v-for="product in productList"
      :key="product.productId"
      cols="12"
      sm="6"
      md="4"
      lg="3"
      xl="2"
      class="product-col"
    >
      <awards-card :product="product" :showBtn="false" />

      <!-- <ProductCard
        class="awards-card"
        :product="product"
        linkComponentName="AwardsDetail"
      >
        <template v-slot:actions>
          <span class="actions">NONE</span>
        </template>
        <template v-slot:productdescr>
          <span
            v-if="product && product.warehousePromo?.view.bubble"
            class="points-information primary--text text-uppercase font-weight-bold"
            >{{ product.warehousePromo?.view.bubble }}</span
          >
          <p v-if="product && product.variations" class="font-weight-bold">
            {{ product.variations[0]?.warehousePromo.view.bubble }}
          </p>
        </template>
        <template v-slot:headers>
          <span class="productheaders"></span>
        </template>
      </ProductCard> -->
    </v-col>
  </v-row>
</template>
<script>
import ProductCard from "@/components/product/ProductCard.vue";

import ProductService from "~/service/productService";
import customService from "@/service/customService";
import AwardsCard from "./AwardsCard.vue";

export default {
  name: "AwardstListGrid",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ProductCard,
    AwardsCard
  },
  props: {
    category: { type: Object, required: true },
    productList: { type: Array, required: true }
  },
  data() {
    return {
      sortFilter: undefined,
      categoryFilter: undefined,
      pager: {},
      page: 1,
      busy: false,
      key: 0,
      loading: false
    };
  },
  methods: {
    async fetchAwardsProductList(isAddMore = false) {
      let _this = this;
      this.loading = true;
      let res = await ProductService.search({
        store_id: this.category?.store.storeId,
        q: this.wordSearched,
        page: this.page,
        page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
        sort: this.sortFilter
      });
      if (res && res.products) {
        if (isAddMore) {
          let prodList = this.productList.concat(res.products);
          _this.productList = prodList;
          console.log("this.productList", this.productList);
        } else {
          this.productList = res.products;
        }
        // this.productList = res.products;
        this.pager = res.page;
      } else {
        this.productList = [];
      }
      this.key++;
      this.loading = false;
    },
    async fetchList() {
      let res = await customService.getList(6, undefined);
      this.listData = res ? res : null;
    },
    sortFilterChanged(newSortFilter) {
      this.sortFilter = newSortFilter;
      this.fetchAwardsProductList();
    },
    categoryChanged(newCategoryFilter) {
      this.categoryFilter = newCategoryFilter;
      this.fetchAwardsProductList();
    },
    wordChanged(wordSearched) {
      this.wordSearched = wordSearched;
      this.fetchAwardsProductList();
    }
  }
};
</script>

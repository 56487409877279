<template>
  <v-row v-if="productList">
    <v-col
      cols="12"
      class="d-flex justify-space-between align-center"
      v-if="awardsContainer"
    >
      <h2>{{ $t("awards.filter.title") }}</h2>
      <span class="text-uppercase font-weight-bold grey--text text--darken-2">
        {{ totalItems }} premi</span
      >
    </v-col>
    <v-col cols="12">
      <v-row class="justify-space-between">
        <v-col cols="12" v-if="facetsFilter && facets">
          <ProductFacetFilterMobile
            v-if="isMobile"
            class="pa-0"
            :facets="facets"
            :hasPromoFilter="false"
            :facetsFilter="facetsFilter"
            :filteredProductsCount="filteredProductsCount"
            :hideFilterString="hideFilterString"
            @handleFacetFilterMobile="handleFacetFilterMobile"
          />

          <ProductFacetFilterDesktop
            v-else
            :facets="facets"
            :facetsFilter="facetsFilter"
            :hideFilterString="hideFilterString"
            @handleFacetFilter="handleFacetFilter"
          />
        </v-col>
        <!--SORT FILTER-->
        <!-- VISIBILE ONLY IN CREATE-YOUR-OFFER PAGE -->
        <v-col cols="12" md="4" v-if="createYourOfferContainer">
          <ProductSortFilter
            v-if="!hideSortFilter"
            :sortFilter="sortFilter"
            :awardsSortOptions="awardsSortOptions"
            @handleSortFilter="handleSortFilter"
          />
        </v-col>
        <!--FREE SEARCH-->
        <v-col cols="12" md="8">
          <v-text-field
            v-model="searchText"
            class="rounded-lg"
            :placeholder="$t('awards.filter.freeSearch')"
            clearable
            dense
            outlined
            hide-details
            hide-no-data
            type="search"
            @focus="onFocus"
            @blur="onBlur"
            @keydown.enter.native.prevent.stop="handleFreeSearch()"
            @click:clear="handleClearSearch()"
          >
            <template slot="append">
              <v-icon
                small
                aria-label="Cerca"
                @click.prevent.stop="handleFreeSearch()"
                color="default"
                >$search</v-icon
              >
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<style lang="scss"></style>
<script>
import ProductFacetFilterMobile from "@/components/product/filters/ProductFacetFilterMobile";
import ProductFacetFilterDesktop from "@/components/product/filters/ProductFacetFilterDesktop";
import ProductSortFilter from "@/components/product/filters/ProductSortFilter";

export default {
  name: "AwardsFilter",
  props: {
    productList: { type: Array, required: true },
    facets: { type: Array },
    facetsFilter: { type: Object },
    sortFilter: { type: Object },
    filteredProductsCount: { type: Number },
    hideFilterString: { type: String, required: false },
    hideSortFilter: { type: String, required: false },
    totalItems: { type: Number, required: false },
    awardsContainer: { type: Boolean, default: true },
    createYourOfferContainer: { type: Boolean, default: false }
  },
  data() {
    return {
      wordSearched: null,
      searchText: null,
      awardsSortOptions: [
        { value: "leaflet", text: "Sconto", icon: "ion-arrow-up-c" },
        { value: "alf_asc", text: "Rilevanza", icon: "ion-arrow-up-c" }
      ]
    };
  },
  components: {
    ProductFacetFilterMobile,
    ProductFacetFilterDesktop,
    ProductSortFilter
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    handleFacetFilter(filters) {
      this.$emit("handleFacetFilter", filters);
    },
    handleFacetFilterMobile(selectedFacetsXS) {
      this.$emit("handleFacetFilterMobile", selectedFacetsXS);
    },
    handleFreeSearch() {
      this.$emit("handleFreeSearch", this.searchText);
    },
    handleClearSearch() {
      this.searchText = null;
      this.$emit("handleFreeSearch", this.searchText);
    },
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    }
  }
};
</script>

<template>
  <div class="profile-container">
    <!-- v-if="!$vuetify.breakpoint.smAndDown" -->
    <Breadcrumb :items="breadcrumb" />
    <v-container class="profile-section">
      <v-row class="awards flex-column" v-if="category && category.store">
        <v-col
          cols="12"
          class="awards-header d-flex flex-column flex-md-row align-center"
        >
          <CategoryTitle :category="category" />
          <!-- <div
            class="w-100 pa-3 d-flex justify-space-between align-center rounded-md accent"
          >
            <span class="ask-awards font-weight-bold"
              >{{
                $t("awards.lists.askAwards", {
                  num:
                    listData && listData.cartItems.length
                      ? listData.cartItems.length
                      : 0
                })
              }}
            </span>
            <span v-if="listData && listData.cartInfos.WISHLIST_ID">
              <v-btn
                color="primary"
                depressed
                :disabled="!isWishListItemPresent"
                large
                :to="{
                  name: 'Booking',
                  params: { listId: listData.cartInfos.WISHLIST_ID },
                  query: {
                    storeId:
                      category && category.store && category.store.storeId
                        ? category.store.storeId
                        : 6
                  }
                }"
              >
                {{ $t("awards.lists.goToCompletePrenotationBtn") }}
              </v-btn>
            </span>
          </div> -->
        </v-col>
        <v-divider class="mx-3 my-5"></v-divider>
        <v-col cols="12" class="awards-filter" v-if="productList"
          ><AwardsFilter
            :totalItems="totItems"
            :productList="productList"
            :facets="facets"
            :facetsFilter="facetsFilter"
            :hideFilterString="hideFilterString"
            :filteredProductsCount="filteredProductsCount"
            @handleFacetFilter="handleFacetFilter"
            @handleFacetFilterMobile="handleFacetFilterMobile"
            @handleFreeSearch="handleFreeSearch"
        /></v-col>
        <v-divider class="mx-3 my-5"></v-divider>
        <template v-if="totItems > 0">
          <v-col cols="12" class="awards-products">
            <span class="d-block mb-5">{{
              $t("awards.products.counter", { count: totItems })
            }}</span>
            <AwardstListGrid
              :category="category"
              :productList="productList"
              v-if="productList && productList.length"
            />
            <v-btn
              color="primary"
              block
              v-intersect="onIntersect"
              :loading="loading"
              @click="loadMore"
              v-if="$vuetify.breakpoint.xs && hasMoreItems"
            >
              <template v-if="hasMoreItems">{{
                $t("products.showMore")
              }}</template>
              <template v-else>{{ $t("products.showAll") }}</template>
            </v-btn>
            <v-pagination
              v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
              :value="pageFilter"
              :page="pager.selPage"
              :length="pager.totPages ? pager.totPages : 0"
              :totalVisible="7"
              @next.stop.prevent="handlePageFilter"
              @prev="handlePageFilter"
              @input="handlePageFilter"
              class="py-6"
              prev-icon="$chevronLeft"
              next-icon="$chevronRight"
              color="primary"
            ></v-pagination>
          </v-col>
        </template>
        <div v-else-if="loading" class="product-list-grid-skeleton">
          <v-row>
            <v-col
              v-for="index in 12"
              :key="index"
              cols="12"
              sm="6"
              md="6"
              lg="3"
              xl="4"
            >
              <v-skeleton-loader
                type="image, list-item-three-line"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </div>
        <span v-else>{{ $t("awards.products.noProducts") }}</span>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
.awards {
  &-header {
    > div {
      flex: 1 1 50%;
      &:nth-child(2) {
        height: fit-content;
      }
    }
  }
  &-filter {
    .v-input__control {
      display: flex;
      align-items: center;
      .v-input__append-inner {
        margin-top: 0 !important;
        align-self: center;
      }
    }
  }
  &-products {
    .action-button {
      display: none;
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import AwardsFilter from "@/components/awards/AwardsFilter.vue";
import AwardstListGrid from "@/components/awards/AwardstListGrid.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import categoryMixins from "~/mixins/category";
import ProductService from "~/service/productService";
import customService from "@/service/customService";

import { mapGetters } from "vuex";

export default {
  name: "Awards",
  props: {
    hideFilterString: { type: String, required: false }
  },
  components: {
    CategoryTitle,
    AwardsFilter,
    AwardstListGrid,
    Breadcrumb
  },
  mixins: [categoryMixins],
  data() {
    return {
      listData: null,
      productList: [],
      facets: [],
      wordSearched: null,
      searchText: null,
      filteredProductsCount: 0,
      pager: {},
      page: 1,
      key: 0,
      products: []
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      pageFilter: "category/pageFilter",
      isAuthenticated: "cart/isAuthenticated"
    }),
    totItems() {
      return this.pager && this.pager.totItems ? this.pager.totItems : 0;
    },
    hasMoreItems() {
      if (this.pager) {
        return this.pager.totPages > this.pager.selPage;
      } else {
        return false;
      }
    },
    facetsFilter: {
      get() {
        return this.$store.state.category.filters.facets;
      },
      set() {
        // this.$store.commit("app/updatedDrawerLeft", value);
      }
    },
    isWishListItemPresent() {
      return this.listData && this.listData.cartItems.length > 0;
    }
  },
  methods: {
    async handleFacetFilter(filter) {
      this.$store.dispatch("category/setFilterFacets", {
        facets: filter.facets,
        parameterName: filter.parameterName
      });
      await this.fetchAwardsProductList();
    },
    async handleFacetFilterMobile(selectedFacetsXS) {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: selectedFacetsXS
      });

      await this.fetchAwardsProductList();
    },
    async fetchAwardsProductList(append) {
      //let _this = this;
      this.loading = true;
      //console.log("this.category", this.category);
      let res = await ProductService.search(
        {
          store_id: this.category?.store.storeId,
          parent_category_id: this.category?.categoryId,
          category_id: this.categoryId || null,
          q: this.wordSearched,
          page: this.pageFilter,
          page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
          sort: this.sortFilter
        },
        this.facetsFilter
      );
      if (this.barcode && res.products.length > 0) {
        this.$router.push({
          name: "AwardsDetail",
          params: { slug: res.products[0].slug }
        });
      } else if (append) {
        this.productList = [...this.productList, ...res.products];
      } else {
        this.productList = res.products;
      }

      this.key++;
      this.pager = res.page;
      this.loading = false;
      this.facetsFilter;
      this.facets = res.facets;
      this.filteredProductsCount = res.page.totItems;
    },
    async fetchList() {
      let res = await customService.getList(6, false);
      this.listData = res ? res : null;
    },
    async handleFreeSearch(searchText) {
      this.wordSearched = searchText;
      await this.fetchAwardsProductList();
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMoreItems) {
        this.$store.dispatch("category/setFilterPage", this.pageFilter + 1);
        this.fetchAwardsProductList(true);
      }
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      this.fetchAwardsProductList();
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    }
  },
  async created() {
    if (this.$vuetify.breakpoint.xsOnly) {
      let page = this.pageFilter;
      for (let i = 1; i <= page; i++) {
        this.$store.dispatch("category/setFilterPage", i);
        await this.fetchAwardsProductList(true);
      }
    } else {
      this.fetchAwardsProductList();
    }
    if (this.isAuthenticated) {
      await this.fetchList();
    }
  },
  metaInfo() {
    return {
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: "https://www.alisupermercati.it/premiali"
        }
      ]
    };
  }
};
</script>
